@mixin dolphin-animation($s: 'M') {
  $diameter: 50;
  $size: 5;
  $duration: 1200;
  $waveDiameter: 22.5;
  $waveSize: .5;


  @if $s == 'S' {
    $diameter: 25;
    $size: 2.5;
    $duration: 1200;
    $waveDiameter: 11.25;
    $waveSize: .25;
  } @else if $s == 'L' {
    $diameter: 100;
    $size: 10;
    $duration: 1200;
    $waveDiameter: 45;
    $waveSize: 1;
  }

  $config: (
          dolphin: (
                  diameter: $diameter,
                  size: $size,
                  color: '#383745',
                  duration: $duration
          ),
          wave: (
                  diameter: $waveDiameter,
                  size: $waveSize,
                  color: '#383745'
          )
  );

  $dolphin: map-get($config, 'dolphin');
  $wave: map-get($config, 'wave');
  $dolphinDiameter: map-get($dolphin, 'diameter');
  $dolphinSize: map-get($dolphin, 'size');
  $dolphinColor: map-get($dolphin, 'color');
  $dolphinDuration: map-get($dolphin, 'duration');
  $waveDiameter: map-get($wave, 'diameter');
  $waveSize: map-get($wave, 'size');
  $waveColor: map-get($wave, 'color');

  $dolphin: map-get($config, 'dolphin');
  $wave: map-get($config, 'wave');
  $dolphinDiameter: map-get($dolphin, 'diameter');
  $dolphinSize: map-get($dolphin, 'size');
  $dolphinColor: map-get($dolphin, 'color');
  $dolphinDuration: map-get($dolphin, 'duration');
  $waveDiameter: map-get($wave, 'diameter');
  $waveSize: map-get($wave, 'size');
  $waveColor: map-get($wave, 'color');

  .loading {
    width: #{$dolphinDiameter}px;
  }


  .dolphin {
    position: relative;
    width: 100%;
    height: #{$dolphinDiameter / 2}px;
    overflow: hidden;
  }

  .dolphin--reverse {
    transform: rotateZ(180deg) rotateY(180deg);
    opacity: 0.08;
    margin-top: 3px;
  }

  .dolphin__anim {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
    transform-origin: 50% 100%;
    animation: dolphinAnim #{$dolphinDuration}ms linear infinite;
    @keyframes dolphinAnim {
      0%, 60% {
        transform: rotate(-50deg);
      }
      100% {
        transform: rotate(20deg);
      }
    }
  }

  %before {
    content: '';
    display: block;
    width: #{$dolphinSize}px;
    height: #{$dolphinSize}px;
    background-color: unquote($dolphinColor);
    border-radius: 50%;
    position: absolute;
  }

  .dolphin__additional {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    animation: dolphinAdditionalAnim #{$dolphinDuration}ms linear infinite;
    @keyframes dolphinAdditionalAnim {
      0%, 10% {
        transform: rotate(-45deg);
      }
      59.5%, 100% {
        transform: rotate(180deg);
      }
    }
    &:before {
      @extend %before;
      left: 0;
      top: 50%;
      z-index: 9;
      transform: translate(0, -50%);
    }
  }

  .dolphin__inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .dolphin__item {
    position: relative;
    width: 100%;
    height: 200%;
    will-change: transform;
    animation: dolphinItemAnim #{$dolphinDuration}ms linear infinite;
    @keyframes dolphinItemAnim {
      0%, 10% {
        transform: rotate(-45deg);
      }
      90%, 100% {
        transform: rotate(315deg);
      }
    }
    &:before {
      @extend %before;
      margin: auto;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border: #{$dolphinSize}px solid transparent;
      border-bottom-color: unquote($dolphinColor);
      border-radius: 50%;
      transform: rotate(45deg);
    }

  }


  .wave {
    position: relative;
    width: 100%;
    margin-top: 3px;
  }

  .wave__item {
    position: absolute;
    top: 50%;
    left: #{($dolphinSize - $waveDiameter) / 2}px;
    width: #{$waveDiameter}px;
    height: #{$waveDiameter}px;
    border: #{$waveSize}px solid unquote($waveColor);
    border-radius: 50%;
    transform: translate(0, -50%) rotateX(70deg) scale(0);
    will-change: transform, opacity;
    animation: waveAnim #{$dolphinDuration}ms ease #{$dolphinDuration / 4}ms infinite;
  }

  .wave__item2 {
    left: auto;
    right: #{($dolphinSize - $waveDiameter) / 2}px;
    animation: waveAnim #{$dolphinDuration}ms ease #{$dolphinDuration / 2}ms infinite;
  }


  @keyframes waveAnim {
    60%, 100% {
      transform: translate(0, -50%) rotateX(70deg) scale(1);
      opacity: 0
    }
  }
}

.dolfin-spinner-small {
  @include dolphin-animation('S');
}

.dolfin-spinner-medium {
  @include dolphin-animation('M');
}

.dolfin-spinner-large {
  @include dolphin-animation('L');
}
